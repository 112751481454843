<template>
    <div>
        <div
      class="
        container-fluid
        header-content
        pos-relative
        height-res-tab-small-300 height-res-200
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 margin-auto text-center w-100">
          <!-- <h4 class="text-white font-28 font-weight-6 mb-10">Data Kependudukan</h4> -->
          <h4 class="text-white font-35 font-weight-7 mb-20">
            Pelayanan Administrasi Kependudukan
          </h4>
          <span class="d-block font-30 text-white font-weight-6 mb-40">
                 <span class="font-14 font-weight-4 d-block  w-100 "> Siap melayani administrasi kependudukan dengan prinsip memudahkan dan cepat. </span>
                <span class="font-14 font-weight-4  d-block  w-100 "> Menggunakan aplikasi Sistem Administrasi Intgrasi Kependudukan (SIOPAPUA) dengan basis data real time yang tersinkronisasi.</span> 
                </span>
                 <br>
        </div>
      </div>
        </div>
         <div class="container-standar mt-40 pl-10 pr-10 pb-10 mb-10 ">
                <div class="container-standar text-center">
                    <div class="row">
                        <div class="col-md-12 mb-10">
                            <div class="card shadow pad-10">
                            <h2 class="font-25 mt-10 font-weight-6 font-primary  pl-4  text-primary mb-10">Administrasi Kependudukan</h2>
                            <h3 class="pl-4 mb-10">Menginformasikan dan memfasilitasi untuk pembuatan : </h3>
                                    <b-tabs content-class="mt-4 mb-10" fill>
                                        <b-tab title="KTP" active>
                                         
                                               
                                                 <div class="col-md-12 text-left pad-10">
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                         <ul class="list-style pl-10"> 
                                                            <li >Surat Pengantar dari RT yang diketahui RW</li> 
                                                         </ul>
                                                    </p>
                                                    <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Dokumen yang didapat :</h3>
                                                    <p>
                                                    <ul class="list-style pl-10">
                                                        <li > 
                                                       Surat Pengantar Desa untuk melakukan proses selanjutnya di Kecamatan atau Dinas Kependudukan dan Catatan Sipil (Dindukcapil)
                                                        </li>
                                                    </ul>
                                                    </p>
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                      <strong class="text-danger">Anda Tidak Perlu Membayar untuk pembuatan KTP</strong> 
                                                    </p>
                                                </div>
                                           
                                        </b-tab>
                                        <b-tab title="KK">
                                             <div class="col-md-12 text-left pad-10">
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                        
                                                        <ul class="list-style pl-10"> 
                                                            <li >Surat Pengantar dari RT yang diketahui RW</li> 
                                                         </ul>
                                                    </p>
                                                    <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Dokumen yang didapat :</h3>
                                                    <p>
                                                          <ul class="list-style pl-10"> 
                                                              <li>
                                                       Surat Pengantar Desa untuk melakukan proses selanjutnya di Kecamatan atau Dinas Kependudukan dan Catatan Sipil (Dindukcapil)
                                                              </li>
                                                          </ul>
                                                    </p>
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                        <strong class="text-danger">Anda Tidak Perlu Membayar untuk pembuatan kartu Keluarga</strong>
                                                    </p>
                                                </div> 
                                        </b-tab>
                                         <b-tab title="Surat Pindah">
                                            
                                                 <div class="col-md-12 text-left pad-10  ">
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                       <ul class="list-style pl-10"> 
                                                            <li >Surat Pengantar dari RT yang diketahui RW</li>
                                                            <li >KTP</li>
                                                         </ul>
                                                    </p>
                                                    <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Dokumen yang didapat :</h3>
                                                    <p>
                                                          <ul class="list-style pl-10"> 
                                                              <li>
                                                       Surat Pengantar Desa untuk melakukan proses selanjutnya di Kecamatan atau Dinas Kependudukan dan Catatan Sipil (Dindukcapil)
                                                              </li>
                                                          </ul>
                                                    </p>
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                        <strong class="text-danger">Anda Tidak Perlu Membayar untuk pembuatan Surat Pindah</strong>
                                                    </p>
                                                </div>
                                           
                                         </b-tab>
                                          <b-tab title="SKCK">
                                        
                                                 <div class="col-md-12 text-left pad-10">
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p> 
                                                         <ul class="list-style pl-10"> 
                                                            <li >Surat Pengantar dari RT yang diketahui RW</li>
                                                            <li >KTP</li>
                                                         </ul>
                                                    </p>
                                                    <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Dokumen yang didapat :</h3>
                                                    <p>
                                                        <ul class="list-style pl-10"> 
                                                            <li>
                                                       Surat Pengantar Desa untuk melakukan proses selanjutnya di Kecamatan atau Dinas Kependudukan dan Catatan Sipil (Dindukcapil)
                                                            </li>
                                                        </ul>
                                                    </p>
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                         <strong class="text-danger"> Anda Tidak Perlu Membayar untuk pembuatan SKCK</strong>
                                                    </p>
                                                </div>
                                           
                                          </b-tab>
                                           <b-tab title="Akta Lahir">
                                             
                                                 <div class="col-md-12 text-left pad-10">
                                                       <span class="text-danger">*Pemohon membawa surat/dokumen asli ke Dispendukcapil</span>
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                        <ul class="list-style pl-10">
                                                          
                                                            <li >Surat Keterangan Kelahiran dari Kelurahan</li>
                                                            <li  >Asli dan fotokopi Surat Keterangan Kelahiran dari dokter/bidan/penolong kelahiran/Nakhoda Kapal Laut atau Pilot Pesawat Terbang 
                                                        dengan memperlihatkan aslinya</li>
                                                        <li  >Surat Nikah/Akta Perkawinan orangtua</li>
                                                        <li  >Fotokopi KK dan KTP orangtua</li>
                                                        <li  >Nama dan identitas saksi pelaporan kelahiran</li>
                                                        <li  >Persetujuan Kepala Dinas/Suku Dinas dalam hal pelaporannya melebihi 60 hari sejak tanggal kelahirannya</li>
                                                         
                                                        </ul> 
                                                    </p> 
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                       <strong class="text-danger"> Anda Tidak Perlu Membayar untuk pembuatan Akta Kelahiran </strong>
                                                    </p>
                                                </div>
                                             
                                          </b-tab>
                                          <b-tab title="Akta Nikah">
                                             
                                                 <div class="col-md-12 text-left pad-10"> 
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                        <ul class="list-style pl-10">
                                                          
                                                            <li >Map berwarna merah untuk menyimpan semua berkas persyaratan</li>
                                                            <li  >Surat keterangan dari masing-masing kelurahan berupa surat N1 sampai dengan N4, asli dan fotokopi (2 set)</li>
                                                        <li  >Fotokopi KTP kedua mempelai yang telah dilegalisasi lurah (2 lembar)</li>
                                                        <li  >Fotokopi KK kedua mempelai yang telah dilegalisasi lurah (2 lembar)</li>
                                                        <li  >Fotokopi akta kelahiran kedua mempelai, asli dan fotokopi (2 lembar) </li>
                                                        <li  >Pas foto suami dan istri berdampingan ukuran 4 x 6 berwarna (6 lembar)</li>
                                                        <li  >Fotokopi KTP dua orang saksi selain orangtua (2 lembar)</li>
                                                        <li  >Fotokopi KTP orangtua kedua mempelai (2 lembar)</li>
                                                        <li  >Surat pernyataan belum pernah menikah dengan materai Rp6000 dan diketahui oleh 2 orang saksi + stempel RT/RW setempat</li>
                                                        <li  >Akta kelahiran masing-masing, asli dan fotokopi (2 lembar)</li>
                                                        <li>Surat nikah perkawinan agama, asli dan fotokopi (2 lembar)</li>
                                                        <li>Surat izin dari atasan/KPI (untuk anggota TNI-Polri) </li> 
                                                        </ul> 
                                                    </p> 
                                                    <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">pendaftaran pernikahan ke catatan sipil untuk mendapatkan akta nikah: :</h3>
                                                     <p>
                                                        <ul class="list-style pl-10">
                                                          
                                                            <li >Pemohon membawa surat/dokumen asli ke Dispendukcapil untuk diverifikasi petugas dan penentuan jadwal pencatatan perkawinan</li>
                                                            <li  >Melakukan pencatatan perkawinan yang dilakukan di instansi pelaksana tempat terjadinya perkawinan</li>
                                                        <li  >Mengisi formulir pencatatan perkawinan pada Disepnduk dan Catatan Sipil dengan melampirkan persyaratan</li>
                                                        <li  >Pejabat pencatatan sipil mencatat pada register akta perkawinan dan menerbitkan kutipan akta perkawinan. </li>
                                                        <li  >Kutipan akta perkawinan diberikan kepada masing-masing suami dan istri. </li>
                                                        <li  >Suami atau istri berkewajiban melaporkan hasil pencatatan perkawinan kepada instansi pelaksana tempat domisilinya.</li> 
                                                        </ul> 
                                                    </p> 

                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                       <strong class="text-danger"> Anda Tidak Perlu Membayar untuk pembuatan Akta Nikah </strong>
                                                    </p>
                                                </div>
                                             
                                          </b-tab>
                                           <b-tab title="Akta Kematian">
                                             
                                                 <div class="col-md-12 text-left pad-10"> 
                                                    <h3 class="font-18 font-weight-6 text-dark mb-8">Persyaratan: </h3>
                                                    <p>
                                                        <ul class="list-style pl-10">
                                                          
                                                            <li >Fotokopi Kartu Tanda Penduduk Almarhum/Almarhumah.</li>
                                                            <li  > Fotokopi KTP pelapor</li>
                                                        <li  >Fotokopi KTP saksi.</li>
                                                        <li  >Fotokopi Akta Kelahiran/Perkawinan/SBKRI (Surat Bukti Kewarganegaraan Republik Indonesia)</li>
                                                        <li  >Surat Keterangan Dari Rumah Sakit (Bila di perlukan).</li>
                                                        <li  >Surat Pengantar Kematian dari Kelurahan.</li>
                                                         
                                                        </ul> 
                                                    </p> 
                                                     <h3 class="font-18 font-weight-6 text-dark mt-3 mb-8">Biaya :</h3>
                                                    <p>
                                                       <strong class="text-danger"> Anda Tidak Perlu Membayar untuk pembuatan Akta Kematian </strong>
                                                    </p>
                                                </div>
                                             
                                          </b-tab>
                                    </b-tabs>
                            </div>
                        </div>
                    </div> 
                </div>
         </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.$store.commit("SET_BG", true);
    }
}
</script>